
@font-face {
  font-family: 'Averta CY';
  src: url('/public/fonts/Averta-Cyrillic-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Averta CY';
  src: url('/public/fonts/Averta-Cyrillic-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'Averta CY';
  src: url('/public/fonts/Averta-Cyrillic-SemiBold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
